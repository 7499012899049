import React from 'react';
import { message } from 'antd';

import { UserPrograms, User } from '~/services/agent';

export default function useUserProgram(id) {
  const { isLogin } = User.getMyProfile();
  const [loading, setLoading] = React.useState(false);
  const { data } = UserPrograms.get({ enabled: isLogin });
  const program = data?.user_programs.find(v => v.program.id === id);
  const [userProgramId, setUserProgramId] = React.useState();
  React.useEffect(() => {
    if (program) {
      setUserProgramId(program.user_program.id);
    }
  }, [program]);

  async function toggleClick() {
    setLoading(true);
    if (isLogin) {
      if (userProgramId) {
        await UserPrograms.delete(userProgramId);
        setUserProgramId(null);
      } else {
        const res = await UserPrograms.add(id);
        setUserProgramId(res.data.user_program_id);
      }
    } else {
      message.error('请先登录');
      setLoading(false);
    }
    setLoading(false);
  }

  return {
    loading,
    toggleClick,
    isActive: userProgramId,
  };
}
