import React from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { getAvatarAddress, bbsEndpoint } from '~/utils/Constants';
import useResponsive from '~/hooks/useResponsive';
import { Discuz } from '~/services/agent';
import OrangeButton from '~/components/OrangeButton';

const Header = () => (
  <div className="w-full text-3xl tracking-[6px] text-primary text-center mb-5">
    <strong className="text-2xl font-bold">招生信息</strong>
  </div>
);

const AdmissionAds = ({ threads }) => {
  const { data } = Discuz.getAdmissionAdThreads('AdmissionAds');
  const { lg } = useResponsive();
  if (!threads) {
    threads = data?.threads;
  }

  return (
    <div className="bg-contain bg-#FAFAFA p-6">
      <div className="admission-carousel max-w-6xl m-auto">
        <Header />
        <Carousel
          dots={{ dotsClass: 'rounded-full' }}
          autoplay
          arrows
          prevArrow={<LeftOutlined className="text-lg" />}
          nextArrow={<RightOutlined className="text-lg" />}>
          {threads &&
            _.chunk(_.shuffle(threads), lg ? 3 : 1).map(group => (
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 mb-5">
                {group.map(({ subject, author, authorid, tid, summary }) => (
                  <a href={bbsEndpoint.THREAD(tid)} target="_blank" className=" text-current" key={tid}>
                    <div className="bg-white rounded shadow-md p-5">
                      <div className="flex py-2 border-b border-b-#DCDCDC">
                        <img src={getAvatarAddress(authorid)} className="w-8 h-8 object-contain mr-2" />
                        <div className="w-full">
                          <div className="text-sm leading-tight break-words line-clamp-2">{subject}</div>
                          <div className="text-primary leading-tight">{author}</div>
                        </div>
                      </div>
                      <div className="my-1 break-words fade">{summary}</div>
                    </div>
                  </a>
                ))}
              </div>
            ))}
        </Carousel>
        <div className="flex justify-center gap-x-6 mt-10 mb-4">
          <a href={bbsEndpoint.POST_THREAD(173)} target="_blank">
            <OrangeButton className="w-[120px] md:w-[220px]">发布招生信息</OrangeButton>
          </a>
          <a href={bbsEndpoint.FORUM(173)} target="_blank">
            <OrangeButton className="w-[120px] md:w-[220px]">所有招生信息</OrangeButton>
          </a>
        </div>
      </div>
      <style jsx global>{`
        .ant-carousel .slick-dots-bottom {
          bottom: -20px !important;
          height: 16px;
          z-index: 0 !important;
        }

        .ant-carousel .slick-dots li.slick-active {
          width: 16px !important;
        }

        .admission-carousel .ant-carousel .slick-dots li button,
        .admission-carousel .ant-carousel .slick-dots li.slick-active button {
          width: 10px !important;
          height: 10px !important;
          border-radius: 10px !important;
          background: #497b89 !important;
        }

        .admission-carousel .ant-carousel .slick-prev,
        .admission-carousel .ant-carousel .slick-next,
        .admission-carousel .ant-carousel .slick-prev:hover,
        .admission-carousel .ant-carousel .slick-next:hover {
          font-size: 20px !important;
          color: #497b89 !important;
          z-index: 10 !important;
          top: unset !important;
          bottom: -20px !important;
        }

        .admission-carousel .ant-carousel .slick-prev,
        .admission-carousel .ant-carousel .slick-prev:hover {
          left: calc(50% - ${(16 * (threads?.length || 0)) / (lg ? 6 : 2) + 50}px) !important;
        }

        .admission-carousel .ant-carousel .slick-next,
        .admission-carousel .ant-carousel .slick-next:hover {
          left: calc(50% + ${(16 * (threads?.length || 0)) / (lg ? 6 : 2) + 23}px) !important;
        }
      `}</style>
    </div>
  );
};

export default AdmissionAds;
