import React from 'react';
import { Rate, Tag, Carousel } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import Link from 'next/link';
import { useIntersection } from 'react-use';
import _ from 'lodash';

import useUniversity from '~/hooks/useUniversity';
import FavoriteButton from '~/components/university/FavoriteButton';
import { getItemValue } from '~/components/FormItems/Utils';
import BasicInfoMiddlePart from '~/components/university/BasicInfoMiddlePart';
import { bbsEndpoint } from '~/utils/Constants';
import SticktyHeader from '~/components/university/SticktyHeader';
import AddProgramButton from '~/components/university/AddProgramButton';

export default function BasicInfo({ readOnly }) {
  const contextData = useUniversity();
  const { college, program, collegeSchool, major, type, id, name, pathname } = contextData;
  const items = [['ad_count', '录取人'], ['count', '申请人'], ['gpa', 'GPA'], ['toefl', 'TOEFL'], ['gre', 'GRE']];
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
  });
  const rating = major?.rating ?? collegeSchool?.rating ?? program?.rating ?? college?.rating;
  const rating_count =
    major?.rating_count ?? collegeSchool?.rating_count ?? program?.rating_count ?? college?.rating_count;
  const releasingResult =
    college?.is_releasing_results || program?.is_releasing_results || major?.is_releasing_results ? (
      <Carousel dotPosition="left" autoplay dots={false} adaptiveHeight>
        {program?.is_releasing_results
          ? program?.is_releasing_results?.map(result => (
              <span
                className="text-right text-sm text-#FF6600 font-semibold"
                key={`${result.planyr}${result.planterm}`}>
                {result.planyr} {result.planterm}申请季正在出结果
              </span>
            ))
          : (major?.is_releasing_results || college?.is_releasing_results)?.map(result => (
              <span
                className="text-right text-sm text-#FF6600 font-semibold h-[18px] overflow-hidden"
                key={`${result.planyr}${result.planterm}${result.planmajor}`}>
                {!_.isEmpty(major?.is_releasing_results) ? `${_.upperCase(result.outname)} ` : ''}
                {result.planyr} {result.planterm}申请季 {result.planmajor} 正在出结果
              </span>
            ))}
      </Carousel>
    ) : null;

  return (
    <>
      <section
        ref={intersectionRef}
        className="lg:shadow-card lg:rounded-t-[30px] overflow-hidden pt-5 lg:pt-0 lg:bg-transparent"
        style={{ background: college?.cover_image || '#497B89' }}>
        <div
          className={`rounded-t-[30px] relative overflow-hidden px-6 lg:px-8 pt-5 lg:pt-6 pb-2 lg:pb-8 rounded-sm flex items-center text-#6E6E6E ${
            college ? 'lg:h-[176px]' : 'lg:h-[131px]'
          } mx-4 lg:mx-0 bg-white mt-0 `}>
          {college && <img src={college.logo_image} className="hidden lg:block w-[120px] h-[120px] mr-8" />}
          {college == null && type === 'major' && (
            <div className="w-[100px] h-[100px] rounded-full text-primary bg-#DEE8E8 font-bold text-xl hidden lg:flex items-center justify-center mr-[51px]">
              {major.short_name}
            </div>
          )}
          <div className="flex flex-col space-y-2 z-10 lg:space-y-0 lg:justify-between h-full">
            {college && (
              <div className="flex items-center space-x-4">
                {program && (
                  <>
                    <Tag color="green">{getItemValue('planmajor', program.major)}</Tag>
                    {program.stat_rank > 0 && (
                      <div className="text-#5BAE93 bg-#D3F4EA rounded-lg text-xs px-2 py-px font-medium">
                        U.S. NEWS 专业排名 #{program.stat_rank}
                      </div>
                    )}
                  </>
                )}
                {type === 'college' && (
                  <div className="text-#FF6600 bg-#FFEDE1 rounded-lg text-xs px-2 py-px font-medium">
                    U.S. NEWS 世界大学排名 #{college.usnews_rank}
                  </div>
                )}
              </div>
            )}
            <BasicInfoMiddlePart />
            <div className="lg:hidden">{releasingResult}</div>
            {rating_count ? (
              <div className="flex items-center">
                {rating > 0 && (
                  <div className="text-#777 font-bold mr-2">
                    <span className="text-xl text-#4E4E4E">{rating}</span>/5
                  </div>
                )}
                <Rate disabled allowHalf value={rating} className="text-#FFA500 text-sm small" />
                <span className="text-xs ml-4 mt-1">
                  <span className="text-#FF6600 underline">{rating_count}</span> 条{' '}
                  <a target="_blank" href={bbsEndpoint.COLLEGE_RATE_SORT(college?.short_name)}>
                    新评价
                  </a>{' '}
                  和{' '}
                  <a target="_blank" href={bbsEndpoint.COLLEGE_RATE_SORT(college?.short_name, 0, 307)}>
                    历史评价
                  </a>
                </span>
              </div>
            ) : (
              <div className="lg:h-5" />
            )}
          </div>
          <div className="flex flex-col items-end flex-1 space-y-3 z-10">
            <div className="hidden lg:block">{releasingResult}</div>
            {type === 'program' && !readOnly && (
              <AddProgramButton id={id} className=" hidden lg:flex bg-#FFF4DF text-#FFA500 border-0" />
            )}
            <div className="hidden lg:flex flex-1 justify-end space-x-6 items-center h-full">
              {items.map(([value, title]) => {
                const realValue = major?.[value] ?? collegeSchool?.[value] ?? program?.[value] ?? college?.[value];
                return (
                  <div className="flex flex-col text-center flex-shrink-0" key={title}>
                    <div className="text-#2E2E2E text-sm font-bold">{realValue}</div>
                    <div className="text-xs">{title}</div>
                  </div>
                );
              })}
            </div>
            {readOnly || college == null || (
              <FavoriteButton
                className=" absolute right-4 bottom-2 lg:relative"
                type={type === 'program' ? 'program' : 'college'}
                id={id}
              />
            )}
          </div>
          <div className="hidden lg:block absolute left-0 -bottom-1 blur">
            <svg width="1250" height="80" viewBox="0 0 1250 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 57C297.5 71 861.5 71 1250 0V80H0V57Z"
                fill={college?.cover_image || '#497B89'}
                fillOpacity="0.6"
              />
            </svg>
          </div>
        </div>
        {readOnly || (
          <div className="h-8 bg-[#FBFBFB] hidden lg:flex items-center text-xs pl-4 space-x-2 ">
            <HomeOutlined />
            <Link href="/">
              <a className="text-#5E5E5E hover:text-primary">Offer多多</a>
            </Link>
            {college && (
              <>
                <span>{'>'}</span>
                <Link href={`/university/${college.short_name.toLowerCase()}`}>
                  <a className="text-#5E5E5E hover:text-primary last:text-primary">{college.school_name}</a>
                </Link>
              </>
            )}
            {type !== 'college' && (
              <>
                <span>{'>'}</span>
                <Link href={`/${pathname}/${id}`}>
                  <a>{name}</a>
                </Link>
              </>
            )}
          </div>
        )}
      </section>
      {intersection?.isIntersecting === false && !readOnly && (
        <SticktyHeader rating={rating} rating_count={rating_count} />
      )}
    </>
  );
}
