import { Rate, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Link from 'next/link';

import FavoriteButton from '~/components/university/FavoriteButton';
import useUniversity from '~/hooks/useUniversity';
import { bbsEndpoint, headerMenus } from '~/utils/Constants';

export default function SticktyHeader({ rating, rating_count }) {
  const { college, name, type, id, major } = useUniversity();

  return (
    <header
      className="hidden lg:block h-16 shadow fixed top-0 left-0 w-screen z-50 bg-white border-t-[5px]"
      style={{ borderColor: college?.cover_image || '#497B89' }}>
      <div className="w-[1140px] mx-auto flex items-center h-full justify-between">
        <img src="/static/logo-primary.svg" className="mr-16 h-[44px]" />
        <div className="flex flex-1">
          {college && <img src={college.logo_image} className="w-[50px] h-12 mr-2 self-center" />}
          {college == null && type === 'major' && (
            <div className="mr-2 w-[50px] h-[50px] rounded-full text-primary bg-#DEE8E8 font-bold text-xl hidden lg:flex items-center justify-center">
              {major.short_name}
            </div>
          )}
          <div className="flex flex-col justify-between">
            <div className="text-xl font-bold leading-none">
              {name}
              {type === 'college' && `(${college.short_name})`}
            </div>
            <div className="flex items-center">
              {college && <span className="mr-4">{college.school_name_cn}</span>}
              {college == null && type === 'major' && <span className="mr-4">{major.cn_name}</span>}
              {rating_count && (
                <>
                  <Rate disabled allowHalf value={rating} className="text-#FFA500 text-sm small" />
                  <span className="text-xs ml-4 mt-1">
                    <span className=" text-#FF6600 underline">{rating_count}</span> 条{' '}
                    <a target="_blank" href={bbsEndpoint.COLLEGE_RATE_SORT(college?.short_name)}>
                      新评价
                    </a>{' '}
                    和{' '}
                    <a target="_blank" href={bbsEndpoint.COLLEGE_RATE_SORT(college?.short_name, 0, 307)}>
                      历史评价
                    </a>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center mr-6">
          <Link href="/results">
            <Button type="text" className="h-full flex items-center">
              <SearchOutlined />
            </Button>
          </Link>
          <div className="flex items-center space-x-4">
            {headerMenus.map(([title, url]) => (
              <Link href={url} key={title}>
                <a className=" text-#5E5E5E hover:text-primary">{title}</a>
              </Link>
            ))}
          </div>
        </div>
        <FavoriteButton type={type === 'program' ? 'program' : 'college'} id={id} />
      </div>
    </header>
  );
}
