import { Button } from 'antd';

export default function OrangeButton({ className = '', ...rest }) {
  return (
    <Button
      className={`font-bold bg-#FFF4DF text-#FC9F4A border-none rounded-lg w-[200px] h-[33px] ${className}`}
      {...rest}
    />
  );
}
