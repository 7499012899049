import React from 'react';
import { Button } from 'antd';

import useUserProgram from '~/hooks/useUserProgram';

const DefaultRender = ({ isActive }) => {
  return isActive ? (
    <>
      <img src="/static/check-orange.svg" className="mr-1" />
      已经在我的选校
    </>
  ) : (
    <>
      <img src="/static/add-orange.svg" className="mr-1" />
      添加到我的选校
    </>
  );
};

export default function AddProgramButton({ id, className, Render = DefaultRender }) {
  const { toggleClick, isActive, loading } = useUserProgram(id);

  return (
    <Button
      disabled={loading}
      className={`flex items-center rounded-lg border ${
        isActive ? 'border-#FC9F4A text-#FC9F4A bg-#FFF4DF' : 'bg-#FAFAFA border-[#E0E0E0]'
      } ${className}`}
      onClick={toggleClick}>
      <Render isActive={isActive} />
    </Button>
  );
}
