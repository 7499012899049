import Link from 'next/link';

import useUniversity from '~/hooks/useUniversity';

export default function BasicInfoMiddlePart() {
  const { college, program, collegeSchool, major, name } = useUniversity();

  if (major) {
    return (
      <div>
        <div className="font-bold text-lg lg:text-xl text-#2E2E2E">{name}</div>
        {college ? (
          <>
            <Link href={`/university/${college.short_name.toLowerCase()}`}>{college.school_name}</Link>
            <div className="text-#6E6E6E">
              <span className="mr-4">{college.school_name_cn}</span> {college.region}, {college.area}
            </div>
          </>
        ) : (
          <div>{major.cn_name}</div>
        )}
      </div>
    );
  }
  if (collegeSchool) {
    return (
      <div>
        <div className="font-bold text-lg text-#2E2E2E leading-none">{collegeSchool.name}</div>
        <Link href={`/university/${college.short_name.toLowerCase()}`}>{college.school_name}</Link>
        <div className="text-#6E6E6E">
          <span className="mr-4">{college.school_name_cn}</span> {college.region}, {college.area}
        </div>
      </div>
    );
  }
  if (program) {
    return (
      <div>
        <div className="font-bold text-lg lg:text-xl text-#2E2E2E">{program.program_full_name}</div>
        <div className="text-#6E6E6E flex flex-col">
          <Link href={`/grad-schools-depts/${program.college_school_id}`}>
            <a>{program.college_school_name}</a>
          </Link>
          <Link href={`/university/${college.short_name.toLowerCase()}`}>
            <a>{college.school_name}</a>
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Link href={`/university/${college.short_name.toLowerCase()}`}>
        <div className="font-bold text-lg lg:text-xl text-#2E2E2E cursor-pointer">
          {college.school_name} ({college.short_name})
        </div>
      </Link>
      <div className="text-#6E6E6E">
        <span className="mr-4">{college.school_name_cn}</span> {college.region && `${college.region},`} {college.area}
      </div>
    </div>
  );
}
